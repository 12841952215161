import React, { CSSProperties } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styled from 'styled-components';

interface IBackgroundSectionProps {
  children?: React.ReactNode;
  fileName?: string;
  style?: any;
  className?: string;
  tag?: string;
  ariaLabel?: string;
}

const BackgroundImageStyled = styled(BackgroundImage)`
  height: 768px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  z-index: 0;
  position: relative;
  margin-bottom: 50px;
  background-color: linear-gradient(134deg, #5b26bf 0%, #5096f4 100%);
  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
`;
const BackgroundColorStyled = styled.section`
  height: 768px;
  width: 100%;
  background-size: cover;
  background-position: center center;
  display: flex;
  align-items: center;
  z-index: 0;
  position: relative;
  margin-bottom: 50px;
  background-color: white;
  @media (max-width: 768px) {
    margin-bottom: 25px;
  }
`;

const BackgroundSection = ({
  children,
  fileName,
  style,
  className,
  tag = 'section',
  ariaLabel = '',
}: IBackgroundSectionProps) => {
  const data = useStaticQuery(
    graphql`
      query {
        placeholderImage: allFile(
          filter: {
            sourceInstanceName: { eq: "images" }
            extension: { regex: "/jpeg|jpg|png|gif/" }
          }
        ) {
          edges {
            node {
              relativePath
              childImageSharp {
                fluid(
                  sizes: "(max-width: 576px) calc(100vw - 20px), (max-width: 768px) calc(100vw - 40px), 1280px"
                  quality: 100
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    `,
  );
  let image = {} as any;
  if (fileName) {
    image = data.placeholderImage.edges.find(({ node }) => node.relativePath === fileName).node;
    if (!image) {
      return null;
    }
  }
  const propStyle = style ? ({ ...style } as CSSProperties) : ({} as CSSProperties);
  return (
    <>
      {Object.keys(image).length > 0 && (
        <BackgroundImageStyled
          fluid={image.childImageSharp.fluid}
          Tag={tag}
          style={{ ...propStyle }}
          aria-label={ariaLabel}
          role="img"
        >
          {children && <>{children}</>}
        </BackgroundImageStyled>
      )}
      {!fileName && (
        <BackgroundColorStyled style={{ ...propStyle }}>
          {children && <>{children}</>}
        </BackgroundColorStyled>
      )}
    </>
  );
};
export default BackgroundSection;
